import Vue from "vue";
import Vuex from "vuex";

import { user } from "./modules/user";
import { posts } from "./modules/post";
import { messages } from "./modules/message";
import { postThread } from "./modules/postThread";
import { members } from "./modules/member";
import { payment } from "./modules/payment";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    message: "",
  },
  getters: {
    getLoaderStatus(state) {
      return state.loading;
    },
    getLoaderMessage(state) {
      return state.message;
    },
  },
  mutations: {
    setLoader(state, status) {
      state.loading = status;
    },
    setLoaderMessage(state, message) {
      state.message = message;
    },
  },
  actions: {},
  modules: {
    user,
    posts,
    messages,
    postThread,
    members,
    payment,
  },
});
