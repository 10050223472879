<template>
  <v-app>
    <Loading :active="loader" loader="dots" color="#4CAF50">
    </Loading> 
    <span class="vld-overlay is-active is-full-page" :active="loader" :style="'top: 57px;color: #2a9131;font-size: 25px;display:'+(loader ? 'flex' : 'none')"> {{ message  }}</span>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Loading from "vue-loading-overlay";
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    Loading: Loading,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      loader: "getLoaderStatus",
      message: "getLoaderMessage",
    }),
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    if(window.innerWidth > 768) {
      window.location.href = process.env.VUE_APP_MEMBER_URL;
      // console.log(window.innerWidth);
      // console.log(window.innerHeight);
    }
    if (
      window.IsDuplicate() &&
      !window.location.href.includes("duplicate-tab")
    ) {
      window.location.href = "/duplicate-tab";
    }
  },
};
</script>
