const hasAccess = (service, userServices) => {
  return userServices.some((userService) => userService.id === service);
};

const isServiceActive = (service, userServices) => {
  return userServices.some(
    (userService) => userService.id === service && userService.is_active
  );
};

export default {
  hasAccess,
  isServiceActive,
};
