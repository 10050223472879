import messageService from "@/services/messages.service";

const state = {
  unReadMessagesCount: 0,
  messages: [],
};

const actions = {
  getMessages({ commit }, payload) {
    return messageService.getMessages(commit, payload);
  },

  getUnReadMessagesCount({ commit }) {
    return messageService.getUnReadMessagesCount(commit);
  },

  createMessages({ commit }, payload) {
    return messageService.createMessage(commit, payload);
  },

  replyMessages({ commit }, payload) {
    return messageService.replyMessage(commit, payload);
  },
};

const mutations = {
  setMessages(state, response) {
    state.messages = response.data;
  },

  setUnReadMessagesCount(state, response) {
    state.unReadMessagesCount = response.data.unread_messages_count;
  },
};

const getters = {
  getMessages(state) {
    return state.messages;
  },

  getUnReadMessagesCount(state) {
    return state.unReadMessagesCount;
  },
};

export const messages = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
