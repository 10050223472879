import Vue from "vue";
const toUsFormat = {
  update: function (el) {
    const handler = function (e) {
      if (e.target.value) {
        const formattedValue = e.target.value
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        e.target.value = !formattedValue[2]
          ? formattedValue[1]
          : "(" +
            formattedValue[1] +
            ") " +
            formattedValue[2] +
            (formattedValue[3] ? "-" + formattedValue[3] : "");
      }
    };
    el.addEventListener("input", handler);
  },
};

const toUsCurrencyFormat = {
  bind: function (el) {
    const handler = function (e) {
      if (e.target.value) {
        const data = e.target.value.replace(/\D/g, "");
        let formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
        });
        e.target.value = formatter.format(data);
      }
    };
    el.addEventListener("input", handler);
  },
};

// Global Directives
Vue.directive("to-us-format", toUsFormat);
Vue.directive("to-us-currency", toUsCurrencyFormat);
