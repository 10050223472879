/* eslint-disable */
const duplicateWindow = function () {
  const localStorageTimeout = 5 * 1000; // 15,000 milliseconds = 15 seconds.
  const localStorageResetInterval = (1 / 2) * 1000; // 10,000 milliseconds = 10 seconds.
  const localStorageTabKey = "my-application-browser-tab";
  //const sessionStorageGuidKey = 'browser-tab-guid';

  const ItemType = {
    Session: 1,
    Local: 2,
  };

  function setCookie(name, value, days) {
    let expires = "";
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
  function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  function GetItem(itemtype) {
    let val = "";
    switch (itemtype) {
      case ItemType.Session:
        val = window.name;
        break;
      case ItemType.Local:
        val = decodeURIComponent(getCookie(localStorageTabKey));
        if (val == undefined) val = "";
        break;
    }
    return val;
  }

  function SetItem(itemtype, val) {
    switch (itemtype) {
      case ItemType.Session:
        window.name = val;
        break;
      case ItemType.Local:
        setCookie(localStorageTabKey, val);
        break;
    }
  }

  function createGUID() {
    window.s4 = function () {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    return (
      window.s4() +
      window.s4() +
      "-" +
      window.s4() +
      "-" +
      window.s4() +
      "-" +
      window.s4() +
      "-" +
      window.s4() +
      window.s4() +
      window.s4()
    );
  }
  function TestIfDuplicate() {
    //console.log("In testTab");
    const sessionGuid = GetItem(ItemType.Session) || createGUID();
    SetItem(ItemType.Session, sessionGuid);

    const val = GetItem(ItemType.Local);
    const tabObj = (val == "" ? null : JSON.parse(val)) || null;

    // If no or stale tab object, our session is the winner.  If the guid matches, ours is still the winner
    if (
      tabObj === null ||
      tabObj.timestamp < new Date().getTime() - localStorageTimeout ||
      tabObj.guid === sessionGuid
    ) {
      function setTabObj() {
        //console.log("In setTabObj");
        const newTabObj = {
          guid: sessionGuid,
          timestamp: new Date().getTime(),
        };
        SetItem(ItemType.Local, JSON.stringify(newTabObj));
      }
      setTabObj();
      setInterval(setTabObj, localStorageResetInterval); //every x interval refresh timestamp in cookie
      return false;
    } else {
      // An active tab is already open that does not match our session guid.
      return true;
    }
  }

  window.IsDuplicate = function () {
    const duplicate = TestIfDuplicate();
    //console.log("Is Duplicate: "+ duplicate);
    return duplicate;
  };

  window.addEventListener("beforeunload", function (event) {
    if (TestIfDuplicate() == false) {
      SetItem(ItemType.Local, "");
    }
  });
};

export default duplicateWindow;
