import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { loadLanguageAsync } from "@/plugins/i18n";
import authUtility from "@/utils/auth.utility";
import {
  MEMBERS_DIRECTORY,
  POSTING,
} from "@/constants/services";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/terms-condition",
    name: "TermCondition",
    component: () => import("../views/TermCondition.vue"),
  },

  {
    path: "/payment-details",
    name: "PaymentDetails",
    component: () => import("../views/PaymentDetails.vue"),
  },
  
  {
    path: "/duplicate-tab",
    name: "DuplicateTab",
    component: () => import("../views/DuplicateTab.vue"),
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/Dashboard.vue"),
    children: [
      {
        path: "profile",
        name: "Profile",
        component: () => import("../views/Profile.vue"),
      },
      {
        path: "message-center",
        name: "MessageCenter",
        component: () => import("../views/MessageCenter.vue"),
        meta: {
          service: POSTING,
        },
      },
      {
        path: "post-thread/:postThreadId/messages",
        name: "ThreadMessages",
        component: () => import("../views/Messages.vue"),
        meta: {
          service: POSTING,
        },
      },
      {
        path: "payment-info",
        name: "PaymentInfo",
        component: () => import("../components/profile/PaymentInfo.vue"),
      },
      {
        path: "unauthorized",
        name: "UnAuthorized",
        component: () => import("../views/UnAuthorized.vue"),
      },
      {
        path: "coming-soon",
        name: "ComingSoon",
        component: () => import("../views/ComingSoon.vue"),
      },
      {
        path: "forbidden",
        name: "Forbidden",
        component: () => import("../views/Forbidden.vue"),
      },
      {
        path: "404-error",
        name: "404Error",
        component: () => import("../views/404Error.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  console.log("1. to: ", to.name);
  console.log("2. from: ", from.name);
  const lang = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : process.env.VUE_APP_LANGUAGE;
  await loadLanguageAsync(lang);
  const user = store.getters["user/getUser"];
  const publicPages = ["Login", "Leads", "TermCondition", "DuplicateTab"];
  if (to.name === "Login" && store.getters["user/isLoggedIn"]) {
    next({ path: "/dashboard" });
  } else if (
    store.getters["user/isLoggedIn"] &&
    !publicPages.includes(to.name) &&
    to.name !== "PaymentDetails"
  ) {
    if (user && user.is_new_user && !user.is_subscribed) {
      next({ name: "PaymentDetails" });
    } else if (user && !user.is_subscribed && !user.is_on_grace_period) {
      next({ name: "PaymentDetails" });
    } else if (user && user.has_failed_payment && !user.is_on_grace_period) {
      next({ name: "PaymentDetails" });
    } else if (
      to.meta.service &&
      !authUtility.hasAccess(
        to.meta.service,
        store.getters["user/getUserServices"]
      ) &&
      authUtility.isServiceActive(
        to.meta.service,
        store.getters["user/getAllServices"]
      )
    ) {
      next({ name: "UnAuthorized" });
    } else if (
      to.meta.service &&
      !authUtility.isServiceActive(
        to.meta.service,
        store.getters["user/getAllServices"]
      )
    ) {
      next({ name: "ComingSoon" });
    } else {
      next();
    }
  } else if (
    !publicPages.includes(to.name) &&
    !store.getters["user/isLoggedIn"]
  ) {
    next({ name: "Login" });
  } else if (
    to.meta.service &&
    !authUtility.hasAccess(
      to.meta.service,
      store.getters["user/getUserServices"]
    )
  ) {
    next({ name: "UnAuthorized" });
  } else if (user && user.is_subscribed) {
    next({ name: "MembersDirectory" });
  } else {
    next();
  }
});

export default router;
