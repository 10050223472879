import axios from "axios";
import { isObject } from "lodash";
import store from "@/store";
import router from "@/router";
import tokenService from "./token.service";
import { AUTH_TOKEN, AUTH_TOKEN_REFRESH, LOGOUT } from "@/constants/apiUrls";
import {
  UNAUTHORIZED,
  INTERNAL_SERVER_ERROR,
  FORBIDDEN_ACCESS,
} from "@/constants/httpStatus";

const http = axios.create({
  baseURL: process.env.VUE_APP_API_HOST,
});

const errorHandler = (error) => {
  let errorObject = { status: error.response.status };

  if (isObject(error.response.data.error)) {
    errorObject.message = error.response.data.message;
  } else if (isObject(error.response.data.errors)) {
    errorObject.message = error.response.data.errors;
  } else {
    errorObject.message = error.response.data.message;
  }

  return Promise.reject(errorObject);
};

http.interceptors.request.use(
  (config) => {
    const header = config.headers["Content-type"];
    if (tokenService.getAccessToken()) {
      config.headers.common[
        "Authorization"
      ] = `Bearer ${tokenService.getAccessToken()}`;
    }
    if (header) {
      config.headers.common["Content-type"] = header;
    } else {
      config.headers.common["Content-type"] = "application/json";
    }

    return config;
  },
  (error) => Promise.reject(error)
);

http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response || error.response.status == INTERNAL_SERVER_ERROR) {
      return Promise.reject({
        status: INTERNAL_SERVER_ERROR,
        message: "some error occured",
      });
    }

    const originalRequest = error.config;

    if (error.response.status === FORBIDDEN_ACCESS) {
      router.push("/dashboard/forbidden");
    }

    if (
      error.response.status === UNAUTHORIZED &&
      originalRequest.url === AUTH_TOKEN
    ) {
      return errorHandler(error);
    }

    if (originalRequest.url === LOGOUT) {
      localStorage.clear();
      window.location.href = "/";
    }

    if (
      error.response.status === UNAUTHORIZED &&
      originalRequest.url === AUTH_TOKEN_REFRESH
    ) {
      store.dispatch("user/logout").then(() => {
        window.location.href = "/";
      });
    }

    if (error.response.status === UNAUTHORIZED && !originalRequest._retry) {
      originalRequest._retry = true;

      return store.dispatch("user/refreshToken").then(() => {
        originalRequest.headers[
          "Authorization"
        ] = `Bearer ${tokenService.getAccessToken()}`;

        return http(originalRequest);
      });
    }

    return errorHandler(error);
  }
);

if (tokenService.getAccessToken()) {
  http.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${tokenService.getAccessToken()}`;
}

export default http;
