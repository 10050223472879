import postThreadService from "@/services/postThread.service";

const state = {
  postThread: {},
  postThreads: [],
};

const actions = {
  getPostThreads({ commit }, query) {
    return postThreadService.getPostThreads(commit, query);
  },

  getPostThread({ commit }, payload) {
    return postThreadService.getPostThread(commit, payload);
  },
};

const mutations = {
  setPostThreads(state, response) {
    state.postThreads = response.data;
  },

  setPostThread(state, response) {
    state.postThread = response.data;
  },
};

const getters = {
  getPostThreads(state) {
    return state.postThreads;
  },
  getPostThread(state) {
    return state.postThread;
  },
};

export const postThread = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
