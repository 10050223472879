import paymentService from "@/services/payment.service";
import tokenService from "@/services/token.service";

const state = {
  setupIntent: {},
  paymentMethod: null,
  invoices: [],
  paymentReceipt: null
};

const actions = {
  setupIntent({ commit }) {
    return paymentService.createSetupIntent(commit);
  },

  defaultPaymentMethod({ commit }) {
    return paymentService.getDefaultPaymentMethod(commit);
  },

  addDuePayment({ commit }, payload) {
    return paymentService.addDuePayment(commit, payload);
  },
  addPaymentMethod({ commit }, payload) {
    return paymentService.addDefaultPaymentMethod(commit, payload);
  },

  getInvoices({ commit }, payload) {
    return paymentService.invoices(commit, payload);
  },

  newPaymentMethod({ commit }, payload) {
    return paymentService.newDefaultPaymentMethod(commit, payload);
  },
  
  getPaymentReceiptUrl({ commit }, payload) {
    return paymentService.paymentReceiptUrl(commit, payload);
  },
};

const mutations = {
  setSetupIntent(state, response) {
    state.setupIntent = response.data;
  },

  setPaymentMethod(state, response) {
    if (Array.isArray(response.data)) {
      state.paymentMethod = null;
    } else {
      state.paymentMethod = response.data;
    }
  },

  setInvoices(state, response) {
    state.invoices = response.data;
  },

  setPaymentReceiptUrl(state, response) {
    state.paymentReceipt = response;
  }
};

const getters = {
  defaultPaymentMethod(state) {
    return state.paymentMethod;
  },

  setupIntent(state) {
    return state.setupIntent;
  },

  getInvoices(state) {
    return state.invoices;
  },

  getPaymentReceiptUrl(state) {
    return state.paymentReceipt;
  },
};

export const payment = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
