export const MEMBERS_DIRECTORY = 1;
export const CAR_HISTORY_REPORT = 2;
export const VIN_SEARCH = 3;
export const INTERCHANGE = 4;
export const SEARCH_INVENTORY = 5;
export const INVOICE = 6;
export const DMV_PROCESSING = 7;
export const CAR_AUCTION = 8;
export const TRADE_CAR_PARTS = 9;
export const POSTING = 10;
export const BROADCASTING = 11;
export const SELLING_EBAY = 12;
export const MARKETING_CAMPAIGN = 14;
export const HOTLINE_NEWS = 15;
