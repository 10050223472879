import memberService from "@/services/member.service";

const state = {
  members: [],
  users: [],
  rooms: [],
};

const actions = {
  getMembers({ commit }, query) {
    return memberService.members(commit, query);
  },

  shareMember({ commit }, payload) {
    return memberService.shareMember(commit, payload);
  },

  getUsers({ commit }, query) {
    return memberService.users(commit, query);
  },

  getRoomList({ commit }) {
    return memberService.getRoomList(commit);
  },
};

const mutations = {
  setMembers(state, response) {
    state.members = response.data;
  },

  setUsers(state, response) {
    state.users = response.data;
  },

  setRoomList(state, response) {
    state.rooms = response.data;
  },
};

const getters = {
  getMembers(state) {
    return state.members;
  },

  getUsers(state) {
    return state.users;
  },

  getRoomList(state) {
    return state.rooms;
  },

};

export const members = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
