import http from "@/services/http.service";
import {
  SETUP_INTENT,
  DEFAULT_PAYMENT_METHOD,
  ADD_DEFAULT_PAYMENT_METHOD,
  ADD_DUE_PAYMENT,
  INVOICES,
  NEW_DEFAULT_PAYMENT_METHOD,
  GET_PAYMENT_RECEIPT_URL
} from "@/constants/apiUrls";

const createSetupIntent = async (commit) => {
  const response = await http.get(`${SETUP_INTENT}`);
  commit("setSetupIntent", response.data);
};

const getDefaultPaymentMethod = async (commit) => {
  const response = await http.get(`${DEFAULT_PAYMENT_METHOD}`);
  commit("setPaymentMethod", response.data);
};

const addDefaultPaymentMethod = async (commit, payload) => {
  await http.post(ADD_DEFAULT_PAYMENT_METHOD, payload);
};

const addDuePayment = async (commit, payload) => {
  await http.post(ADD_DUE_PAYMENT, payload);
};

const invoices = async (commit, payload) => {
  const response = await http.get(INVOICES, { params: payload });
  commit("setInvoices", response.data);
};

const newDefaultPaymentMethod = async (commit, payload) => {
  await http.post(NEW_DEFAULT_PAYMENT_METHOD, payload);
};

const paymentReceiptUrl = async (commit, payload) => {
  const response = await http.post(GET_PAYMENT_RECEIPT_URL, payload);
  commit("setPaymentReceiptUrl", response.data);
};

export default {
  createSetupIntent,
  getDefaultPaymentMethod,
  addDefaultPaymentMethod,
  addDuePayment,
  invoices,
  newDefaultPaymentMethod,
  paymentReceiptUrl
};
