import http from "@/services/http.service";
import {
  MESSAGES,
  UNREAD_MESSAGES_COUNT,
  REPLY_MESSAGE,
} from "@/constants/apiUrls";

const getMessages = async (commit, payload) => {
  const response = await http.get(`${MESSAGES}/${payload}`);
  commit("setMessages", response.data);
};

const getUnReadMessagesCount = async (commit) => {
  const response = await http.get(`${UNREAD_MESSAGES_COUNT}`);
  commit("setUnReadMessagesCount", response.data);
};

const createMessage = async (commit, payload) => {
  await http.post(MESSAGES, payload);
};

const replyMessage = async (commit, payload) => {
  const body = {
    message: payload.body,
  };
  await http.post(REPLY_MESSAGE.replace("{post_id}", payload.post_id), body);
};

export default {
  getMessages,
  getUnReadMessagesCount,
  createMessage,
  replyMessage,
};
