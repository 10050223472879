import tokenService from "@/services/token.service";
import authService from "@/services/auth.service";

const state = {
  loggedIn: !!tokenService.getAccessToken() || false,
  accessToken: tokenService.getAccessToken(),
  user: tokenService.getUserData(),
  services: tokenService.getServices() || [],
  allServices: tokenService.getAllServices() || [],
  salesTaxRate: [],
  paymentInfo: null,
};

const getters = {
  getAccessToken(state) {
    return state.accessToken;
  },
  isLoggedIn(state) {
    return state.loggedIn;
  },
  getUser(state) {
    return state.user;
  },
  getUserServices(state) {
    return state.services;
  },
  getSalesTaxRate(state) {
    return state.salesTaxRate;
  },
  getAllServices(state) {
    return state.allServices;
  },
  getPaymentStatus(state) {
    return state.paymentInfo;
  },
};

const actions = {
  login({ commit }, payload) {
    return authService.login(commit, payload);
  },
  getAllServices({ commit }) {
    return authService.getAllServices(commit);
  },
  logout({ commit }) {
    return authService.logout(commit);
  },
  getUser({ commit }) {
    return authService.getUser(commit);
  },
  refreshToken({ commit }) {
    return authService.refreshToken(commit);
  },
  updateProfile({ commit }, payload) {
    return authService.updateProfile(commit, payload);
  },
  updateSalesTaxRate({ commit }, payload) {
    return authService.updateSalesTaxRate(commit, payload);
  },
  getSalesTaxRate({ commit }) {
    return authService.getSalesTaxRate(commit);
  },
  getProfile({ commit }) {
    return authService.profile(commit);
  },
  getPaymentStatus({ commit }) {
    return authService.getPaymentStatus(commit);
  }
};

const mutations = {
  loginSuccess(state, response) {
    state.loggedIn = true;
    state.accessToken = response.data.token;
    tokenService.setAccessToken(response.data.token);
  },
  logout(state) {
    state.loggedIn = false;
    state.user = {};
    state.accessToken = null;
    tokenService.clear();
  },
  setUser(state, response) {
    state.user = response.data;
    state.services = state.user.services;
    tokenService.setUserData(response.data);
    tokenService.setJanusRoom(response.data);
    tokenService.setAllServices(state.allServices);
  },
  setSalesTaxRate(state, response) {
    state.salesTaxRate = response;
  },
  setAllServices(state, response) {
    state.allServices = response.data;
  },
  setPaymentStatus(state, response) {
    state.paymentInfo = response.data;
    state.user = response.data;
    tokenService.setUserData(response.data);
  },
};

export const user = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
