import http from "@/services/http.service";
import tokenService from "@/services/token.service";
import {
  AUTH_TOKEN,
  LOGOUT,
  AUTH_TOKEN_REFRESH,
  PROFILE,
  UPDATE_PROFILE,
  UPDATE_SALES_TAX_RATE,
  GET_SALES_TAX_RATE,
  SERVICES,
  GET_PAYMENT_STATUS
} from "@/constants/apiUrls";

const login = async (commit, payload) => {
  const response = await http.post(AUTH_TOKEN, payload);
  commit("loginSuccess", response.data);
  await profile(commit);
};

const logout = async (commit) => {
  await http.post(LOGOUT);
  commit("logout");
};

const profile = async (commit) => {
  const response = await http.get(PROFILE);
  await getAllServices(commit);
  commit("setUser", response.data);
};

const updateProfile = async (commit, payload) => {
  const response = await http.put(UPDATE_PROFILE, payload);
  commit("setUser", response.data);
};

const updateSalesTaxRate = async (commit, payload) => {
  const response = await http.post(UPDATE_SALES_TAX_RATE, payload);
  commit("setUser", response.data);
};

const getSalesTaxRate = async (commit) => {
  const response = await http.get(GET_SALES_TAX_RATE);
  commit("setSalesTaxRate", response.data);
};

const refreshToken = async (commit) => {
  const response = await http.post(AUTH_TOKEN_REFRESH);
  http.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${tokenService.getAccessToken()}`;
  commit("loginSuccess", response.data);
};

const getAllServices = async (commit) => {
  const response = await http.get(SERVICES);
  commit("setAllServices", response.data);
};

const getPaymentStatus = async (commit) => {
  const response = await http.get(GET_PAYMENT_STATUS);
  commit("setPaymentStatus", response.data);
};

export default {
  login,
  logout,
  refreshToken,
  profile,
  updateProfile,
  updateSalesTaxRate,
  getSalesTaxRate,
  getAllServices,
  getPaymentStatus
};
