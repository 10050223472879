import postService from "@/services/post.service";

const state = {
  posts: [],
  imageResponse: [],
  fileResponse: [],
};

const actions = {
  getPosts({ commit }, query) {
    return postService.posts(commit, query);
  },

  createPost({ commit }, payload) {
    return postService.createPost(commit, payload);
  },

  uploadImage({ commit }, query) {
    return postService.uploadImage(commit, query);
  },
  uploadFile({ commit }, query) {
    return postService.uploadFile(commit, query);
  },
};

const mutations = {
  setPosts(state, response) {
    state.posts = response.data;
  },
  setImageResponse(state, response) {
    state.imageResponse = response.data;
  },
  setFileResponse(state, response) {
    state.fileResponse = response.data;
  },
};

const getters = {
  getPosts(state) {
    return state.posts;
  },
  getImageResponse(state) {
    return state.imageResponse;
  },
  getFileResponse(state) {
    return state.fileResponse;
  },
};

export const posts = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
